import './ContactForm.scss';

const ContactForm = () => {
    return (
        <div>

        </div>
    )
}

export default ContactForm
