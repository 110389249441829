import TourDates from '../../components/TourDates/TourDates';
import './Shows.scss';

const Shows = () => {
    return (
        <div>

            <TourDates />

        </div>
    )
}

export default Shows
