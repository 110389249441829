import './PageNotFound.scss';

const PageNotFound = () => {
    return (
        <div>
            <h1>Page Not Found</h1>
            <p>Sorry, but ain't shit here.</p>

        </div>
    )
}

export default PageNotFound
